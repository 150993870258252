import PropTypes from 'prop-types'
import { IconAlertOctagon } from "@tabler/icons"
import { FormattedMessage, useIntl } from "react-intl"
import { Tooltip } from "../Tooltip"
import { useDebounce, validateField } from '../../utils'
import './index.css'

/**
 * Primary UI component for user interaction
 */
export const Input = (props) => {
  const {
    id,
    showErrorTooltip,
    showErrorText,
    isExpandable,
    rows,
    cols, 
    assistiveText, 
    action,
    autoFocus, 
    blurCallback,
    onChange,
    clickCallback,
    callback,
    className,
    disabled,
    errorCallback,
    error, 
    icon, 
    iconPlacement, 
    iconClassName,
    onEnterCallback,
    placeholder, 
    label, 
    type,
    validationRules, 
    value,
    defaultValue,
    prefix,
    postfix,
    refProp,
    fontSize,
    maxLength,
    min,
    dataAttribute,
    toolTipDataAttribute,
    name,
    onBlur,
    reactIntlId,
    reactIntlAssistiveTextId,
    reactIntlPlaceholderId,
    reactIntlPostfixId,
    ...rest 
  } = props;
  const isTextArea = (type === 'textarea') || false;

  const styles = {
    global: 'font-[14px] font-rubik text-secondary w-full',
    action: {
      primary: `bg-white border ${error ? 'border-danger' : 'border-gray-600'}`,
      secondary: 'bg-gray-400'
    }
  }

  const { global, action: actionStyles } = styles
  const typeStyles = global.concat(` ${actionStyles[action]}`)
  const disabledStyles = 'pointer-events-none opacity-50'
  const intl = useIntl();
  
  const getIconPlacementPadding = () => {
    if(error){
      return { paddingRight: '36px'}
    }
     if(icon){
      if(iconPlacement === "right"){
        return { paddingRight: '36px'}
      }
      if(iconPlacement === "left"){
        return { paddingLeft: '36px'}
      }
    }
    return
  }

  const onEnter = (e) => {
    if(e.key === "Enter"){
      onEnterCallback(e)
    }
  }

  const inputProps = {
    id,
    rows,
    cols,
    type,
    name,
    onBlur,
    className: `
      w-full ${fontSize ? fontSize : 'text-sm'} ${prefix && !postfix ? 'rounded-br-[4px] rounded-tr-[4px]' : postfix && !prefix ? 'rounded-bl-[4px] rounded-tl-[4px]' : postfix && prefix ? '' : 'rounded'} ${typeStyles} ${disabled && disabledStyles} placeholder-[gray-800] 
      focus:outline-none focus:ring-offset-0 ${!error && 'focus:ring-2 focus:ring-gray'} pl-[12px] pr-[8px] min-h-[32px]
      ${className} ${isTextArea && `p-[16px] ${!isExpandable && 'resize-none'}`}
    `,
    style: getIconPlacementPadding(),
    ref: refProp && refProp,
    ...rest,
    disabled,
    placeholder: reactIntlPlaceholderId ? intl.formatMessage({ id: reactIntlPlaceholderId, defaultMessage: placeholder }) : placeholder,
    value: typeof value === 'string' ? value.trimStart() : value,
    defaultValue,
    onBlur: blurCallback,
    autoFocus,
    onKeyPress: onEnter,
    min,
    maxLength,
    onChange: (e) => {
      if (errorCallback) {
        const errorMessage = validateField({
          fieldValue: e.target.value,
          validationRules
        });

        errorCallback(errorMessage);
      }
      onChange && onChange(e);
    },
    ...(dataAttribute && {
      "data-attribute": `input-${dataAttribute}`
    })
  }

  return (
    <div className={`${global}`}>
      {
        label &&
        <div className='mb-[4px] text-sm text-gray-900 leading-[14.22px]'>
          {
            reactIntlId ?
            <FormattedMessage id={reactIntlId} defaultMessage={label}/> : label
          }
        </div>
      }
      <div className={`w-full flex-1 relative inline-block border rounded border-transparent`}>
        {
          error ? 
          !showErrorText && showErrorTooltip ? <span className={`absolute top-[50%] translate-y-[-50%] right-[10px]`}>
            <Tooltip 
              label={error} 
              action="danger" 
              top="top-[-10px]" 
              fixed={error && error}
              {...(toolTipDataAttribute && { dataAttribute: toolTipDataAttribute })}
            >
              <IconAlertOctagon className="w-[20px] h-[20px]" color="#DC3535"/>
            </Tooltip>
          </span>: null 
          :
          icon ?
          <span onClick={clickCallback} className={`absolute top-[8px] ${iconClassName} ${iconPlacement === 'right' ? 'right-[10px]' : 'left-[10px]'}`}>
            {icon}
          </span> : null
        }
        <div className="flex items-center w-full">
            {
              prefix &&
              <div className="h-[32px] px-[16px] text-sm text-secondary-light rounded-tl-[4px] rounded-bl-[4px] bg-gray-400 flex items-center justify-center">{prefix}</div>
            }
            {
              type === 'textarea' ?
              <textarea {...inputProps}></textarea> 
              : 
              <input {...inputProps} />
            }
            
            {
              postfix &&
              typeof postfix === 'string' ?
              <div className="h-[32px] px-[16px] text-sm text-gray-900 rounded-tr-[4px] rounded-br-[4px] bg-gray-400 flex items-center justify-center">
                { reactIntlPostfixId ? <FormattedMessage id={reactIntlPostfixId} defaultMessage={postfix} /> : postfix }
              </div>:
              postfix
            }
        </div>
        {error && showErrorText ? <small className="block text-sm text-danger mt-1" >{error}</small> : null }
      </div>
      
      { assistiveText && <div className={`text-[10px] mt-[4px] ${assistiveText && 'text-secondary'}`}>
        {
          reactIntlAssistiveTextId ? <FormattedMessage id={reactIntlAssistiveTextId} defaultMessage={assistiveText}/> : assistiveText
        }
      </div> }
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  action: PropTypes.oneOf(["primary", "secondary"]),
  type: PropTypes.oneOf(['text', 'password', 'date', 'number', 'time', 'week', 'textarea']),
  assistiveText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  iconPlacement: PropTypes.oneOf(['right', 'left']),
  iconClassName: PropTypes.string,
  icon: PropTypes.element,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onChange: PropTypes.func,
  blurCallback: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number, undefined]),
  clickCallback: PropTypes.func,
  className: PropTypes.string,
  errorCallback: PropTypes.func,
  validationRules: PropTypes.array,
  autoFocus: PropTypes.bool,
  onEnterCallback: PropTypes.func,
  maxLength: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  fontSize: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  refProp: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })
  ]),
  showErrorTooltip: PropTypes.bool,
  showErrorText: PropTypes.bool,
  dataAttribute: PropTypes.string,
  toolTipDataAttribute: PropTypes.string,
  reactIntlId: PropTypes.string,
  reactIntlAssistiveTextId: PropTypes.string,
  reactIntlPlaceholderId: PropTypes.string
};

Input.defaultProps = {
  showErrorTooltip: true,
  showErrorText: false,
  isExpandable: true,
  rows: 0,
  cols: 0,
  blurCallback: () => {},
  clickCallback: PropTypes.func,
  errorCallback: () => {},
  label: '',
  action: 'primary',
  type: 'text',
  placeholder: '',
  assistiveText: '',
  disabled: false,
  iconPlacement: 'left',
  error: '',
  maxLength: "",
  // value: '',
  className: 'bg-white',
  validationRules: [],
  // onChange: () => console.log(""),
  autoFocus: false,
  prefix: '',
  postfix: '',
  onEnterCallback: (e) => {
    if (e?.key === "Enter"){ 
      console.log("") 
    }
  },
  reactIntlId: "",
  reactIntlAssistiveTextId: "",
  reactIntlPlaceholderId: ""
};