import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef } from 'react';
import PropTypes from "prop-types";

export const Popup = ({ component, content, className, alignRight }) => {
  const componentRef = useRef();
  // useEffect(() => {
  //   if(componentRef.current){
  //     componentRef.current?.addEventListener('click', e => e.stopPropagation())
  //   }
  // },[])
  return (
    <Popover className="relative flex items-center">
      {({ close, open }) => {
        return (
          <>
            <Popover.Button ref={componentRef}>
              {() => component({ componentRef, open })}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
            <Popover.Panel
              static
              style={{ boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.15)' }}
              className={`absolute z-20 bg-white rounded-[8px] top-full mt-2 w-max ${className} ${alignRight && 'right-0'}`}>
              {(props) => content({ ...props, close })}
            </Popover.Panel>
          </Transition>
          </>
        )
      }}
    </Popover>
  )
};

Popup.propTypes = {
  component: PropTypes.func,
  content: PropTypes.func,
  className: PropTypes.string,
  alignRight: PropTypes.bool
};

Popup.defaultProps = {
  className: 'p-[20px]',
  alignRight: false
};